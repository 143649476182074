import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_result = _resolveComponent("a-result")!

  return (_openBlock(), _createBlock(_component_a_result, {
    status: "404",
    title: "404",
    style: {"background":"none"},
    "sub-title": "可能由于切换组织，导致您当前无法访问此菜单。即将跳转页面"
  }, {
    extra: _withCtx(() => [
      _createVNode(_component_a_button, { type: "primary" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.msg) + "秒后跳转欢迎页面……", 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}