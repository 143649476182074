
import { defineComponent, ref } from 'vue';
import { Result } from 'ant-design-vue';
import store from '@/store';
import { GENERATE_ROUTES } from '@/store/modules/user/actions';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Exception404',
  setup() {
    const router = useRouter();
    const msg = ref(5);
    setTimeout(() => {
      store.dispatch(`user/${GENERATE_ROUTES}`).then(allowRouters => {
        if (allowRouters) {
          router.push({ path: allowRouters[0].path });
        }
      });
    }, msg.value * 1000);
    setInterval(() => {
      msg.value -= 1;
    }, 1000);

    return {
      msg,
    };
  },
  components: {
    // custom register component
    [Result.name]: Result,
  },
});
